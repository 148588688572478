import $ from "jquery";
import 'bootstrap-star-rating';

import "@hotwired/turbo-rails"
import "@hotwired/stimulus"

window.initStarRating = function() {
  console.log('eoo')
  $("input.star-rating").rating(
    {
      glyphicon: false,
      ratingClass: 'rating-fa',
      step: 1,
      starCaptions: {
        1: '1 estrella',
        2: '2 estrellas',
        3: '3 estrellas',
        4: '4 estrellas',
        5: '5 estrellas'
      },
      starCaptionClasses: {
        1: 'label label-default',
        2: 'label label-default',
        3: 'label label-default',
        4: 'label label-default',
        5: 'label label-default'
      },
      clearButton: '<i class="fa fa-minus-circle"></i>',
      clearCaption: "Sin valorar",
      size: 'sm',
    }
  ) 
}

initStarRating()
